<template>
  <form
    data-component-name="RequestDemoVSA"
    autocomplete="off"
    @input.passive="setFormFillingStart"
    @submit="sendFormData"
  >
    <AppInput
      v-model="firstname"
      :label="$t('First_Name')"
      :error="errors.firstname"
      required
      type="text"
    />

    <AppInput
      v-model="lastname"
      :label="$t('Last_Name')"
      :error="errors.lastname"
      required
      type="text"
    />

    <AppInput
      v-model="email"
      :label="$t('Corporate_Email')"
      :error="errors.email"
      required
      type="email"
    />

    <AppInput
      v-model="company"
      :label="$t('Company')"
      :error="errors.company"
      required
      type="text"
    />

    <AppDropdown.Base
      v-model="relationType"
      :options="LEAD_TYPE_OPTIONS"
      :label="$t('RequestDemoVSA.relation')"
      :error="errors.relationType"
      required
    />

    <AppDropdown.Base
      v-model="jobTitle"
      :options="JOB_TITLE_OPTIONS"
      :label="$t('Role')"
      :error="errors.jobTitle"
      required
    />

    <AppDropdown.Base
      v-model="requestReason"
      :options="REASON_OPTIONS"
      :label="$t('RequestDemoVSA.reason-for-request')"
      :error="errors.requestReason"
      required
    />

    <PhoneInput
      v-model="phone"
      ref="phoneInputRef"
      :error="errors.phone"
      :country-i-s-o="country.isoCode"
      required
    />

    <div class="terms">
      <div class="vsa-terms">
        <AppCheckbox v-model="vsaTerms" id="vsaTerms" />

        <p>
          <i18n-t
            :class="{ error: errors.vsaTerms }"
            keypath="Forms.GDPR.USA"
            tag="label"
            for="vsaTerms"
          >
            <NuxtLink :to="localePath(PATHS.VSA_POLICY.path)" target="_blank">
              {{ $t(PATHS.VSA_POLICY.i18nKey) }}
            </NuxtLink>
          </i18n-t>
        </p>
      </div>

      <Forms.GDPR
        v-model="allowPolicy"
        :error="errors.allowPolicy"
        :country-i-s-o="country.isoCode"
      />
    </div>

    <Buttons.Regular :disabled="!meta.valid" accent="purple">
      {{ btnText }}

      <SvgIcon name="arrow-forward" />
    </Buttons.Regular>
  </form>
</template>

<script setup lang="ts">
// validation
import * as yup from 'yup';
import { toTypedSchema } from '@vee-validate/yup';

// components
import AppCheckbox from '~/components/redesign/AppCheckbox.vue';
import AppDropdown from '~/components/redesign/AppDropdown';
import AppInput from '~/components/redesign/AppInput.vue';
import Buttons from '~/components/redesign/Buttons';
import PhoneInput from '~/components/redesign/PhoneInput.vue';
import Forms from '~/components/redesign/Forms';

// services
import { Cookie } from '~/services';

// stores
import { useCommonStore } from '~/stores/common';

// composables
import { useI18n, useLocalePath } from '#i18n';
import { useForm } from 'vee-validate';
import { storeToRefs } from 'pinia';

// constants
import { PATHS, REGEX, API_ENDPOINTS } from '~/constants';

// utils
import omit from 'lodash.omit';
import pick from 'lodash.pick';

// JSON
import countries from '~/data/countries.json';
import EMEA_ISO_CODES from '~/data/emea-iso-codes.json';

// types
import type { Countries, Country } from '~/types/country';

// exceptions
import { FormValidationError } from '~/exceptions';

const JOB_TITLE_OPTIONS = [
  'Technical Resource',
  'Technical Sales',
  'Technical Marketing',
] as const;

const LEAD_TYPE_OPTIONS = [
  'Alliance',
  'Channel Partner',
  'Customer',
  'Prospect',
] as const;

const REASON_OPTIONS = ['Training', 'Demo', 'Testing'] as const;

const props = withDefaults(
  defineProps<{
    product: string;
    page: string;
    btnText?: string;
    gtmEvent?: string;
  }>(),
  {
    btnText: 'Submit',
    gtmEvent: 'partners',
  },
);

const emit = defineEmits<{
  submitted: [];
  loading: [value: boolean];
}>();

const { t } = useI18n();
const localePath = useLocalePath();
const { $osano, $gtm, $pp } = useNuxtApp();
const { ipInfo } = storeToRefs(useCommonStore());

const phoneInputRef = ref<null | { isValid(): boolean }>(null);

const validationSchema = toTypedSchema(
  yup.object({
    firstname: yup
      .string()
      .trim()
      .max(128, ({ max }) => t('Validations.max', { length: max }))
      .required(t('Validations.This_field_is_required'))
      .default($pp?.get('firstname')),

    lastname: yup
      .string()
      .trim()
      .max(128, ({ max }) => t('Validations.max', { length: max }))
      .required(t('Validations.This_field_is_required'))
      .default($pp?.get('lastname')),

    email: yup
      .string()
      .max(128, ({ max }) => t('Validations.max', { length: max }))
      .required(t('Validations.This_field_is_required'))
      .matches(REGEX.EMAIL, t('Validations.Invalid_email_address'))
      .companyEmail()
      .email()
      .default($pp?.get('email')),

    company: yup
      .string()
      .trim()
      .max(128, ({ max }) => t('Validations.max', { length: max }))
      .required(t('Validations.This_field_is_required'))
      .default($pp?.get('company')),

    jobTitle: yup
      .string()
      .oneOf(JOB_TITLE_OPTIONS)
      .required(t('Validations.This_field_is_required')),

    relationType: yup
      .string()
      .oneOf(LEAD_TYPE_OPTIONS)
      .required(t('Validations.This_field_is_required')),

    requestReason: yup
      .string()
      .oneOf(REASON_OPTIONS)
      .required(t('Validations.This_field_is_required')),

    country: yup
      .object()
      .shape({
        active: yup.boolean(),
        integrationValue: yup.string(),
        isoCode: yup.string(),
        label: yup.string(),
        orgDefault: yup.boolean(),
        standard: yup.boolean(),
        visible: yup.boolean(),
      })
      .required(t('Validations.This_field_is_required')),

    state: yup
      .object()
      .shape({
        active: yup.boolean(),
        integrationValue: yup.string(),
        isoCode: yup.string(),
        label: yup.string(),
        standard: yup.boolean(),
        visible: yup.boolean(),
      })
      .default(undefined)
      .when('country', {
        is: (country: Country) => {
          return ['Canada', 'United States'].includes(country?.label);
        },
        then: schema =>
          schema.required(t('Validations.This_field_is_required')),
        otherwise: schema => schema.notRequired(),
      }),

    phone: yup
      .string()
      .trim()
      .test({
        message: t('Validations.Invalid_phone'),
        test: () => phoneInputRef.value?.isValid(),
      })
      .required(t('Validations.This_field_is_required'))
      .default($pp?.get('phone') || ''),

    comment: yup
      .string()
      .trim()
      .max(2048, ({ max }) => t('Validations.max', { length: max })),

    allowPolicy: yup.boolean().when('country', {
      is: (value?: Country) => EMEA_ISO_CODES.includes(value?.isoCode),
      then: schema =>
        schema
          .oneOf([true], t('Validations.This_field_is_required'))
          .required(t('Validations.This_field_is_required')),
      otherwise: schema => schema.notRequired(),
    }),

    vsaTerms: yup
      .boolean()
      .oneOf([true], t('Validations.This_field_is_required'))
      .required(t('Validations.This_field_is_required')),
  }),
);

const {
  errors,
  defineField,
  handleSubmit,
  resetForm,
  meta,
  setErrors,
  setFieldValue,
  setValues,
} = useForm({ validationSchema });

onMounted(() => {
  setValues(
    {
      relationType: $pp.get('relationType'),
      jobTitle: $pp.get('jobTitle'),
      requestReason: $pp.get('requestReason'),
    },
    false,
  );
});

const [firstname] = defineField('firstname');
const [lastname] = defineField('lastname');
const [email] = defineField('email');
const [company] = defineField('company');
const [jobTitle] = defineField('jobTitle');
const [relationType] = defineField('relationType');
const [requestReason] = defineField('requestReason');
const [country] = defineField('country');
const [state] = defineField('state');
const [phone] = defineField('phone');
const [allowPolicy] = defineField('allowPolicy');
const [vsaTerms] = defineField('vsaTerms');

watch(
  ipInfo,
  info => {
    if (!info) return;

    const foundCountry = (countries as Countries).find(
      country => country.isoCode === info.iso_code,
    );

    if (foundCountry) {
      setFieldValue('country', foundCountry);

      if ('states' in foundCountry) {
        const foundState = foundCountry.states.find(
          item => item.isoCode === info.state,
        );

        if (foundState) {
          setFieldValue('state', foundState);
        }
      }
    }
  },
  { immediate: true },
);

const formFillingStart = ref<null | number>(null);
const resetFormFillingStart = () => {
  formFillingStart.value = null;
};
const setFormFillingStart = () => {
  if (formFillingStart.value !== null) return;
  formFillingStart.value = Date.now();
};
const getFormFillingSeconds = (): number | undefined => {
  if (formFillingStart.value === null) return;
  return (Date.now() - formFillingStart.value) / 1000;
};

const sendFormData = handleSubmit(async values => {
  const requestBody = {
    ...omit(values, [
      'allowPolicy',
      'vsaTerms',
      'jobTitle',
      'relationType',
      'requestReason',
    ]),

    title: values.jobTitle,
    lead_type: values.relationType,
    reason: values.requestReason,

    country: values.country.label,
    state: values.state?.label,

    start_time: formFillingStart.value?.toString(),
    of_form_duration: getFormFillingSeconds()?.toString(),

    cookie: Cookie.getCookieArray(),

    page: props.page,
    product: props.product,

    href: window.location.href,

    entry_page: Cookie.get('EntryPage') ?? '',
    referrer_page: Cookie.get('RefererPage') || '',

    consent: $osano.cm.getConsent(),
  };

  resetFormFillingStart();
  emit('loading', true);

  const {
    public: { api_app: baseURL },
  } = useRuntimeConfig();

  try {
    await $fetch(API_ENDPOINTS.VSA, {
      baseURL,
      method: 'POST',
      body: requestBody,
      credentials: 'include',
    });

    const { title, lead_type, reason, ...fieldsToUpdate } = pick(requestBody, [
      'firstname',
      'lastname',
      'email',
      'company',
      'country',
      'title',
      'lead_type',
      'reason',
      'phone',
    ]);

    $pp.updateBulk({
      ...fieldsToUpdate,
      jobTitle: title,
      relationType: lead_type,
      requestReason: reason,
    });

    $gtm.push({ event: props.gtmEvent, email: values.email });
    resetForm();
    Cookie.clearAfterSubmit();
    emit('submitted');

    void $fetch(API_ENDPOINTS.VSA_TRACK_DOWNLOAD, { baseURL, method: 'POST' });
  } catch (error) {
    if (error instanceof FormValidationError) {
      setErrors(error.fields);
    }
  } finally {
    emit('loading', false);
  }
});
</script>

<style scoped lang="scss">
@import '$/functions/token';
@import '$/mixins/typography';
@import '$/mixins/flex';
@import '$/mixins/media';
@import '$/variables/shadows';

[data-component-name='RequestDemoVSA'] {
  background-color: token('surf-cont-primary');

  @include flex-start-start;
  flex-direction: column;
  gap: 1.5rem;

  @include tablet {
    padding: 1rem;
  }

  @include mobile {
    padding: 0;
  }

  .terms {
    .vsa-terms {
      -webkit-text-size-adjust: none;

      @include flex-start-center;
      flex-direction: row;

      label {
        margin: 0.125rem 0 0.5rem 0.25rem;
      }

      p {
        @include caption;

        label {
          @include caption;
          display: block;
          margin-bottom: 0.5rem;
          cursor: pointer;

          &.error {
            @include caption;
            color: token('error');
          }
        }

        a {
          color: token('link');
          text-decoration: underline;
        }
      }
    }
  }

  [data-component-name='Buttons.Regular'] {
    align-self: flex-end;

    @include mobile {
      align-self: stretch;
    }
  }
}
</style>
