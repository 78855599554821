<template>
  <form
    data-component-name="Forms.GetFreeDemo"
    autocomplete="off"
    data-id="GetFreeDemo"
    @input.passive="setFormFillingStart"
    @change.passive="setFormFillingStart"
    @submit="submitHandler"
  >
    <div class="step-1">
      <p class="title">
        <strong>{{ $t('Step') }} 1:</strong>
        {{ $t('Forms.GetFreeDemo.step-1.title') }}
      </p>

      <ul class="demo-types">
        <li v-for="demo of DEMOS" :key="demo.name" class="demo-type">
          <Buttons.Form
            v-bind="demo"
            :checked="page === demo.name"
            @click="setPageType($event, demo.name)"
          />
        </li>
      </ul>
    </div>

    <div
      :title="isDemoTypeSelected ? undefined : $t('Forms.GetFreeDemo.tooltip')"
      :class="['step-2', { disabled: !isDemoTypeSelected }]"
    >
      <p class="title">
        <strong>{{ $t('Step') }} 2:</strong>
        {{ $t('Forms.GetFreeDemo.step-2.title') }}
      </p>

      <AppInput
        v-model="firstname"
        :label="$t('First_Name')"
        :error="errors.firstname"
        :size
        :disabled="!isDemoTypeSelected"
        required
        fill="outline"
        name="firstname"
      />

      <AppInput
        v-model="lastname"
        :label="$t('Last_Name')"
        :error="errors.lastname"
        :size
        :disabled="!isDemoTypeSelected"
        required
        fill="outline"
        name="lastname"
      />

      <AppInput
        v-model="email"
        :label="$t('Corporate_Email')"
        :error="errors.email"
        :size
        :disabled="!isDemoTypeSelected"
        required
        fill="outline"
        type="email"
        name="email"
      />

      <AppInput
        v-model="company"
        :label="$t('Company')"
        :error="errors.company"
        :size
        :disabled="!isDemoTypeSelected"
        required
        fill="outline"
        name="company"
      />

      <AppInput
        v-model="title"
        :label="$t('Job_Title')"
        :error="errors.title"
        :size
        :disabled="!isDemoTypeSelected"
        required
        fill="outline"
        name="job-title"
      />

      <AppRadioGroup
        v-model="lead_type"
        v-slot="{ value }"
        :label="$t('Your_role') + ':'"
        :error="errors.lead_type"
        :options="LEAD_TYPES_KEYS"
        :disabled="!isDemoTypeSelected"
        inline
        required
        name="lead-type"
        id="lead-type"
      >
        {{ LEAD_TYPES[value] }}
      </AppRadioGroup>

      <AppDropdown.Base
        v-model="country"
        :options="activeCountries"
        :label="$t('Country')"
        :search-placeholder="$t('Search_by_country_name')"
        :error="errors.country"
        :size
        :disabled="!isDemoTypeSelected"
        searchable
        required
        key-attribute="label"
        fill="outline"
      />

      <AppDropdown.Base
        v-if="isUSA || isCanada"
        v-model="state"
        :options="activeStates"
        :label="$t('State')"
        :search-placeholder="$t('Search_by_state_name')"
        :error="errors.state"
        :size
        :disabled="!isDemoTypeSelected"
        searchable
        required
        key-attribute="label"
        fill="outline"
      />

      <PhoneInput
        ref="phoneInputRef"
        v-model="phone"
        :error="errors.phone"
        :country-i-s-o="country.isoCode"
        :disabled="!isDemoTypeSelected"
        :size
        required
        fill="outline"
      />

      <Forms.GDPR
        v-model="GDPR"
        :country-i-s-o="country.isoCode"
        :error="errors.GDPR"
        :disabled="!isDemoTypeSelected"
      />

      <Buttons.Regular
        :accent
        :disabled="!meta.valid || !isDemoTypeSelected"
        data-id="submit-GetFreeDemo"
      >
        {{ btnText }}
        <SvgIcon name="arrow-forward" />
      </Buttons.Regular>
    </div>
  </form>
</template>

<script setup lang="ts">
// validation
import * as yup from 'yup';
import { toTypedSchema } from '@vee-validate/yup';

// components
import Buttons from '~/components/redesign/Buttons';
import AppInput from '~/components/redesign/AppInput.vue';
import AppDropdown from '~/components/redesign/AppDropdown';
import PhoneInput from '~/components/redesign/PhoneInput.vue';
import AppRadioGroup from '~/components/redesign/AppRadioGroup.vue';
import Forms from '~/components/redesign/Forms';

// services
import Cookie from '~/services/Cookie';

// stores
import { useCommonStore } from '~/stores/common';

// composables
import { useI18n } from '#i18n';
import { useForm } from 'vee-validate';
import { storeToRefs } from 'pinia';

// utils
import omit from 'lodash.omit';
import pick from 'lodash.pick';
import isString from '~/utils/isString';
import emitNativeEvent from '~/utils/emitNativeEvent';

// constants
import * as REGEX from '~/constants/regex';
import { DEMO } from '~/constants/api-endpoints';

// JSON
import countries from '~/data/countries.json';
import EMEA_ISO_CODES from '~/data/emea-iso-codes.json';

// types
import type {
  Countries,
  Country,
  CountryWithStates,
  State,
} from '~/types/country';
import type { BaseButtonProps } from '~/components/redesign/Buttons/Base.types';

// exceptions
import { FormValidationError } from '~/exceptions';

const props = withDefaults(
  defineProps<{
    product: string;
    btnText?: string;
    gtmEvent?: string;
    accent?: BaseButtonProps['accent'];
    size?: 'small' | 'medium' | 'large';
  }>(),
  {
    btnText: 'Submit',
    gtmEvent: 'demo',
    accent: 'orange',
    size: 'medium',
  },
);

const emit = defineEmits<{
  submitted: [];
  loading: [value: boolean];
}>();

const { t } = useI18n();
const { ipInfo } = storeToRefs(useCommonStore());
const { $osano, $gtm, $pp } = useNuxtApp();

const phoneInputRef = ref<null | { isValid(): boolean }>(null);

const LEAD_TYPES = {
  CUSTOMER: t('Customer'),
  PARTNER: t('Partner'),
} as Record<string, string>;

const LEAD_TYPES_KEYS = Object.keys(LEAD_TYPES);

const DEMOS = [
  {
    name: 'request-live-demo-1-1',
    icon: 'headset',
    title: t('Forms.GetFreeDemo.step-1.live.title'),
    description: t('Forms.GetFreeDemo.step-1.live.description'),
  },
  {
    name: 'request-live-demo-prerecorded',
    icon: 'live-demo',
    title: t('Forms.GetFreeDemo.step-1.prerecordered.title'),
    description: t('Forms.GetFreeDemo.step-1.prerecordered.description'),
  },
] as const;

const validationSchema = toTypedSchema(
  yup.object({
    page: yup
      .string()
      .oneOf(DEMOS.map(({ name }) => name))
      .required(t('Validations.This_field_is_required'))
      .default($pp?.get('page')),

    firstname: yup
      .string()
      .trim()
      .max(128, ({ max }) => t('Validations.max', { length: max }))
      .required(t('Validations.This_field_is_required'))
      .default($pp?.get('firstname')),

    lastname: yup
      .string()
      .trim()
      .max(128, ({ max }) => t('Validations.max', { length: max }))
      .required(t('Validations.This_field_is_required'))
      .default($pp?.get('lastname')),

    email: yup
      .string()
      .trim()
      .max(128, ({ max }) => t('Validations.max', { length: max }))
      .matches(REGEX.EMAIL, t('Validations.Invalid_email_address'))
      .companyEmail()
      .email()
      .required(t('Validations.This_field_is_required'))
      .default($pp?.get('email')),

    company: yup
      .string()
      .trim()
      .max(128, ({ max }) => t('Validations.max', { length: max }))
      .required(t('Validations.This_field_is_required'))
      .default($pp?.get('company')),

    title: yup
      .string()
      .trim()
      .max(128, ({ max }) => t('Validations.max', { length: max }))
      .required(t('Validations.This_field_is_required'))
      .default($pp?.get('title')),

    lead_type: yup
      .string()
      .oneOf(LEAD_TYPES_KEYS)
      .required(t('Validations.This_field_is_required')),

    country: yup
      .object()
      .default({ active: true, label: 'United States', isoCode: 'US' })
      .required(t('Validations.This_field_is_required')),

    state: yup
      .object()
      .default({ active: true, label: 'Massachusetts', isoCode: 'MA' })
      .when('country', {
        is: (country?: Country) =>
          ['CA', 'US'].includes(country?.isoCode || ''),
        then: schema =>
          schema.required(t('Validations.This_field_is_required')),
        otherwise: schema => schema.notRequired(),
      }),

    phone: yup
      .string()
      .trim()
      .test({
        message: t('Validations.Invalid_phone'),
        test: () => phoneInputRef.value?.isValid(),
      })
      .required(t('Validations.This_field_is_required'))
      .default($pp?.get('phone') || ''),

    GDPR: yup.boolean().when('country', {
      is: (value?: Country) => EMEA_ISO_CODES.includes(value?.isoCode),
      then: schema =>
        schema
          .oneOf([true], t('Validations.This_field_is_required'))
          .required(t('Validations.This_field_is_required')),
      otherwise: schema => schema.notRequired(),
    }),
  }),
);

const {
  errors,
  defineField,
  handleSubmit,
  resetForm,
  meta,
  setFieldValue,
  resetField,
  setErrors,
} = useForm({ validationSchema });

onMounted(() => {
  resetField('lead_type', {
    value: $pp.get('lead_type') || LEAD_TYPES_KEYS[0],
  });
});

const [page] = defineField('page');
const [firstname] = defineField('firstname');
const [lastname] = defineField('lastname');
const [email] = defineField('email');
const [company] = defineField('company');
const [title] = defineField('title');
const [lead_type] = defineField('lead_type');
const [country] = defineField('country');
const [state] = defineField('state');
const [phone] = defineField('phone');
const [GDPR] = defineField('GDPR');

const activeCountries = computed(() =>
  (countries as Countries).filter(country => country.active),
);

const isUSA = computed(() => country.value?.isoCode === 'US');
const isCanada = computed(() => country.value?.isoCode === 'CA');

const countryHasStates = computed(
  () => country.value && 'states' in country.value,
);
const activeStates = computed(() =>
  countryHasStates.value
    ? (country.value as CountryWithStates).states.filter(state => state.active)
    : [],
);

const isDemoTypeSelected = computed(() => isString(page.value));

watch(
  ipInfo,
  info => {
    if (!info) return;

    const foundCountry = (countries as Countries).find(
      country => country.isoCode === info.iso_code,
    );

    if (foundCountry) {
      setFieldValue('country', foundCountry);

      let foundState: State | undefined;

      if ('states' in foundCountry) {
        foundState = foundCountry.states.find(
          item => item.isoCode === info.state,
        );
      }

      setFieldValue('state', foundState || null);
    }
  },
  { immediate: true },
);

watch(country, (newCountry, oldCountry) => {
  if (newCountry.label === oldCountry.label) return;
  resetField('state', { value: null });
});

const setPageType = ({ target }: Event, name: string) => {
  setFieldValue('page', name);

  // fix for form filling duration
  if (target instanceof HTMLElement) {
    emitNativeEvent(target, 'change');
  }
};

const formFillingStart = ref<null | number>(null);
const resetFormFillingStart = () => {
  formFillingStart.value = null;
};
const setFormFillingStart = () => {
  if (formFillingStart.value !== null) return;
  formFillingStart.value = Date.now();
};
const getFormFillingSeconds = (): number | undefined => {
  if (formFillingStart.value === null) return;
  return (Date.now() - formFillingStart.value) / 1000;
};

const submitHandler = handleSubmit(async values => {
  const requestBody = {
    ...omit(values, 'GDPR'),

    country: values.country.label,
    state: values.state?.label,

    start_time: formFillingStart.value?.toString(),
    of_form_duration: getFormFillingSeconds()?.toString(),

    product: props.product,
    href: window.location.href,
    cookie: Cookie.getCookieArray(),

    entry_page: Cookie.get('EntryPage') || '',
    referrer_page: Cookie.get('RefererPage') || '',

    consent: $osano.cm.getConsent(),
  };

  resetFormFillingStart();
  emit('loading', true);

  const {
    public: { api_app: baseURL },
  } = useRuntimeConfig();

  try {
    await $fetch(DEMO, {
      method: 'POST',
      baseURL,
      body: requestBody,
      credentials: 'include',
    });

    $pp.updateBulk(
      pick(requestBody, [
        'page',
        'firstname',
        'lastname',
        'email',
        'company',
        'title',
        'phone',
        'lead_type',
      ]),
    );

    $gtm.push({ event: props.gtmEvent, email: values.email });
    resetForm({ values: pick(values, ['country', 'state']) });
    Cookie.clearAfterSubmit();
    emit('submitted');
  } catch (error) {
    if (error instanceof FormValidationError) {
      setErrors(error.fields);
    }
  } finally {
    emit('loading', false);
  }
});
</script>

<style scoped lang="scss">
@import '$/mixins/typography';
@import '$/mixins/size';
@import '$/mixins/flex';
@import '$/functions/token';

[data-component-name='Forms.GetFreeDemo'] {
  .step-1 {
    margin-bottom: 1.5rem;

    p.title {
      @include body-3;
      margin-bottom: 1rem;
    }

    ul.demo-types {
      @include flex-stretch-sb;
      gap: 0 1rem;

      li.demo-type {
        width: 50%;
      }
    }
  }

  .step-2 {
    @include flex-start-start;
    flex-direction: column;
    gap: 1rem;

    p.title {
      @include body-3;
    }

    :deep([data-component-name='AppRadioGroup']) {
      strong {
        @include body-3;
      }
    }

    [data-component-name='Buttons.Regular'] {
      align-self: flex-end;

      @include mobile {
        align-self: stretch;
      }
    }

    &.disabled {
      p.title {
        color: token('text-tertiary');
      }
    }
  }
}
</style>
