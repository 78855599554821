<template>
  <form
    data-component-name="Forms.RequestDemo"
    data-id="requestDemo"
    autocomplete="off"
    @input.passive="setFormFillingStart"
    @submit="submitHandler"
  >
    <AppInput
      v-model="firstname"
      :label="$t('First_Name')"
      :error="errors.firstname"
      :size
      required
      name="firstname"
    />

    <AppInput
      v-model="lastname"
      :label="$t('Last_Name')"
      :error="errors.lastname"
      :size
      required
      name="lastname"
    />

    <AppInput
      v-model="email"
      :label="$t('Corporate_Email')"
      :error="errors.email"
      :size
      required
      type="email"
      name="email"
    />

    <AppInput
      v-model="company"
      :label="$t('Company')"
      :error="errors.company"
      :size
      required
      name="company"
    />

    <AppInput
      v-model="title"
      :label="$t('Job_Title')"
      :error="errors.title"
      :size
      required
      name="job-title"
    />

    <AppRadioGroup
      v-model="lead_type"
      v-slot="{ value }"
      :options="RADIO_OPTIONS_KEYS"
      :label="$t('Your_role') + ':'"
      :error="errors.lead_type"
      inline
      required
      name="role"
      id="lead_type"
    >
      {{ RADIO_OPTIONS[value] }}
    </AppRadioGroup>

    <AppDropdown.Base
      v-model="country"
      :options="activeCountries"
      :label="$t('Country')"
      :search-placeholder="$t('Search_by_country_name')"
      :error="errors.country"
      :size
      searchable
      required
      key-attribute="label"
    />

    <AppDropdown.Base
      v-if="isUSA || isCanada"
      v-model="state"
      :options="activeStates"
      :label="$t('State')"
      :search-placeholder="$t('Search_by_state_name')"
      :error="errors.state"
      :size
      searchable
      required
      key-attribute="label"
    />

    <PhoneInput
      v-model="phone"
      ref="phoneInputRef"
      :error="errors.phone"
      :country-i-s-o="country.isoCode"
      :size
      required
    />

    <AppTextarea
      v-model="comment"
      :placeholder="$t('Type_here')"
      :error="errors.comment"
      name="comment"
    />

    <Forms.GDPR
      v-model="GDPR"
      :error="errors.GDPR"
      :country-i-s-o="country.isoCode"
    />

    <Buttons.Regular
      :accent
      :disabled="!meta.valid"
      data-id="submit-requestDemo"
    >
      {{ btnText }}
      <SvgIcon name="arrow-forward" />
    </Buttons.Regular>
  </form>
</template>

<script setup lang="ts">
// validation
import * as yup from 'yup';
import { toTypedSchema } from '@vee-validate/yup';

// components
import Buttons from '~/components/redesign/Buttons';
import AppInput from '~/components/redesign/AppInput.vue';
import AppRadioGroup from '~/components/redesign/AppRadioGroup.vue';
import AppDropdown from '~/components/redesign/AppDropdown';
import PhoneInput from '~/components/redesign/PhoneInput.vue';
import AppTextarea from '~/components/redesign/AppTextarea.vue';
import Forms from '~/components/redesign/Forms';

// services
import { Cookie } from '~/services';

// stores
import { useCommonStore } from '~/stores/common';

// composables
import { useI18n } from '#i18n';
import { useForm } from 'vee-validate';
import { storeToRefs } from 'pinia';

// utils
import omit from 'lodash.omit';
import pick from 'lodash.pick';

// constants
import { REGEX } from '~/constants';
import { DEMO } from '~/constants/api-endpoints';

// JSON
import countries from '~/data/countries.json';
import EMEA_ISO_CODES from '~/data/emea-iso-codes.json';

// types
import type { BaseButtonProps } from '~/components/redesign/Buttons/Base.types';
import type {
  Countries,
  Country,
  CountryWithStates,
  State,
} from '~/types/country';

// exceptions
import { FormValidationError } from '~/exceptions';

const props = withDefaults(
  defineProps<{
    product: string;
    page: string;
    btnText?: string;
    gtmEvent?: string;
    accent?: BaseButtonProps['accent'];
    size?: 'small' | 'medium' | 'large';
  }>(),
  {
    btnText: 'Submit',
    gtmEvent: 'demo',
    accent: 'orange',
    size: 'medium',
  },
);

const emit = defineEmits<{
  submitted: [];
  loading: [value: boolean];
}>();

const { t } = useI18n();
const { $osano, $gtm, $pp } = useNuxtApp();
const { ipInfo } = storeToRefs(useCommonStore());

const phoneInputRef = ref<null | { isValid(): boolean }>(null);
const RADIO_OPTIONS = {
  CUSTOMER: t('Customer'),
  PARTNER: t('Partner'),
} as Record<string, string>;

const RADIO_OPTIONS_KEYS = Object.keys(RADIO_OPTIONS);

const validationSchema = toTypedSchema(
  yup.object({
    firstname: yup
      .string()
      .trim()
      .max(128, ({ max }) => t('Validations.max', { length: max }))
      .required(t('Validations.This_field_is_required'))
      .default($pp?.get('firstname')),

    lastname: yup
      .string()
      .trim()
      .max(128, ({ max }) => t('Validations.max', { length: max }))
      .required(t('Validations.This_field_is_required'))
      .default($pp?.get('lastname')),

    email: yup
      .string()
      .trim()
      .max(128, ({ max }) => t('Validations.max', { length: max }))
      .required(t('Validations.This_field_is_required'))
      .matches(REGEX.EMAIL, t('Validations.Invalid_email_address'))
      .companyEmail()
      .email()
      .default($pp?.get('email')),

    company: yup
      .string()
      .trim()
      .max(128, ({ max }) => t('Validations.max', { length: max }))
      .required(t('Validations.This_field_is_required'))
      .default($pp?.get('company')),

    title: yup
      .string()
      .trim()
      .max(128, ({ max }) => t('Validations.max', { length: max }))
      .required(t('Validations.This_field_is_required'))
      .default($pp?.get('title')),

    lead_type: yup
      .string()
      .oneOf(RADIO_OPTIONS_KEYS)
      .required(t('Validations.This_field_is_required')),

    country: yup
      .object()
      .default({ active: true, label: 'United States', isoCode: 'US' })
      .required(t('Validations.This_field_is_required')),

    state: yup
      .object()
      .default({ active: true, label: 'Massachusetts', isoCode: 'MA' })
      .when('country', {
        is: (country?: Country) =>
          ['CA', 'US'].includes(country?.isoCode || ''),
        then: schema =>
          schema.required(t('Validations.This_field_is_required')),
        otherwise: schema => schema.notRequired(),
      }),

    phone: yup
      .string()
      .trim()
      .test({
        message: t('Validations.Invalid_phone'),
        test: () => phoneInputRef.value?.isValid(),
      })
      .required(t('Validations.This_field_is_required'))
      .default($pp?.get('phone') || ''),

    comment: yup
      .string()
      .trim()
      .max(2048, ({ max }) => t('Validations.max', { length: max })),

    GDPR: yup.boolean().when('country', {
      is: (value?: Country) => EMEA_ISO_CODES.includes(value?.isoCode),
      then: schema =>
        schema
          .oneOf([true], t('Validations.This_field_is_required'))
          .required(t('Validations.This_field_is_required')),
      otherwise: schema => schema.notRequired(),
    }),
  }),
);

const {
  errors,
  defineField,
  handleSubmit,
  resetForm,
  meta,
  setFieldValue,
  resetField,
  setErrors,
} = useForm({ validationSchema });

onMounted(() => {
  resetField('lead_type', {
    value: $pp.get('lead_type') || RADIO_OPTIONS_KEYS[0],
  });
});

const [firstname] = defineField('firstname');
const [lastname] = defineField('lastname');
const [email] = defineField('email');
const [company] = defineField('company');
const [title] = defineField('title');
const [lead_type] = defineField('lead_type');
const [country] = defineField('country');
const [state] = defineField('state');
const [phone] = defineField('phone');
const [comment] = defineField('comment');
const [GDPR] = defineField('GDPR');

const activeCountries = computed(() =>
  (countries as Countries).filter(country => country.active),
);

const isUSA = computed(() => country.value?.isoCode === 'US');
const isCanada = computed(() => country.value?.isoCode === 'CA');

const countryHasStates = computed(
  () => country.value && 'states' in country.value,
);
const activeStates = computed(() =>
  countryHasStates.value
    ? (country.value as CountryWithStates).states.filter(state => state.active)
    : [],
);

watch(
  ipInfo,
  info => {
    if (!info) return;

    const foundCountry = (countries as Countries).find(
      country => country.isoCode === info.iso_code,
    );

    if (foundCountry) {
      setFieldValue('country', foundCountry);

      let foundState: State | undefined;

      if ('states' in foundCountry) {
        foundState = foundCountry.states.find(
          item => item.isoCode === info.state,
        );
      }

      setFieldValue('state', foundState || null);
    }
  },
  { immediate: true },
);

watch(country, (newCountry, oldCountry) => {
  if (newCountry.label === oldCountry.label) return;
  resetField('state', { value: null });
});

const formFillingStart = ref<null | number>(null);
const resetFormFillingStart = () => {
  formFillingStart.value = null;
};
const setFormFillingStart = () => {
  if (formFillingStart.value !== null) return;
  formFillingStart.value = Date.now();
};
const getFormFillingSeconds = (): number | undefined => {
  if (formFillingStart.value === null) return;
  return (Date.now() - formFillingStart.value) / 1000;
};

const submitHandler = handleSubmit(async values => {
  const requestBody = {
    ...omit(values, 'GDPR'),

    country: values.country.label,
    state: values.state?.label,

    start_time: formFillingStart.value?.toString(),
    of_form_duration: getFormFillingSeconds()?.toString(),

    cookie: Cookie.getCookieArray(),

    page: props.page,
    product: props.product,

    href: window.location.href,

    entry_page: Cookie.get('EntryPage') || '',
    referrer_page: Cookie.get('RefererPage') || '',

    consent: $osano.cm.getConsent(),
  };

  resetFormFillingStart();
  emit('loading', true);

  const {
    public: { api_app: baseURL },
  } = useRuntimeConfig();

  try {
    await $fetch(DEMO, {
      method: 'POST',
      baseURL,
      body: requestBody,
      credentials: 'include',
    });

    $pp.updateBulk(
      pick(requestBody, [
        'firstname',
        'lastname',
        'email',
        'company',
        'title',
        'phone',
        'lead_type',
      ]),
    );

    $gtm.push({ event: props.gtmEvent, email: values.email });
    resetForm({ values: pick(values, ['country', 'state']) });
    Cookie.clearAfterSubmit();
    emit('submitted');
  } catch (error) {
    if (error instanceof FormValidationError) {
      setErrors(error.fields);
    }
  } finally {
    emit('loading', false);
  }
});
</script>

<style scoped lang="scss">
@import '$/mixins/flex';
@import '$/mixins/media';
@import '$/functions/token';
@import '$/mixins/typography';

[data-component-name='Forms.RequestDemo'] {
  background-color: token('surf-cont-primary');

  @include flex-start-start;
  flex-direction: column;
  gap: 1.5rem;

  :deep([data-component-name='AppRadioGroup']) {
    padding: 0;
  }

  [data-component-name='Buttons.Regular'] {
    align-self: flex-end;

    @include mobile {
      align-self: stretch;
    }
  }
}
</style>
