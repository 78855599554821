import Wrapper from './Wrapper.vue';

import ApplyRole from './ApplyRole.vue';
import GetAsset from  './GetAsset.vue';
import BecomePartner from  './BecomePartner.vue';
import ContactUs from  './ContactUs.vue';
import HelpFeedback from './HelpFeedback.vue';
import RequestDemo from  './RequestDemo.vue';
import RequestDemoVSA from  './RequestDemoVSA.vue';
import RequestDemoShort from  './RequestDemoShort.vue';
import RequestBundle from './RequestBundle.vue';
import GetFreeDemo from './GetFreeDemo.vue';
import Subscribe from './Subscribe.vue';
import GDPR from './GDPR/index.vue';
import VerifyEmail from './VerifyEmail.vue';

export default {
  Wrapper,

  ApplyRole,
  GetAsset,
  BecomePartner,
  ContactUs,
  HelpFeedback,
  RequestDemo,
  RequestDemoVSA,
  RequestDemoShort,
  RequestBundle,
  GetFreeDemo,
  Subscribe,
  GDPR,
  VerifyEmail,
};
