<template>
  <form
    data-component-name="ContactUs"
    autocomplete="off"
    data-id="contactUs"
    @input.passive="setFormFillingStart"
    @submit="submitHandler"
  >
    <AppInput
      v-model.trim="fullname"
      :label="$t('Full_Name')"
      :error="errors.fullname"
      required
      type="text"
      name="fullname"
    />

    <AppInput
      v-model="email"
      :label="$t('Corporate_Email')"
      :error="errors.email"
      required
      type="email"
      name="email"
    />

    <AppInput
      v-model="company"
      :label="$t('Company')"
      :error="errors.company"
      required
      type="text"
      name="company"
    />

    <AppRadioGroup
      v-model="of_partner"
      v-slot="{ value }"
      :options="RADIO_OPTIONS_KEYS"
      :label="$t('are_you_of_partner')"
      :error="errors.of_partner"
      required
      name="of_partner"
      id="of_partner"
    >
      {{ RADIO_OPTIONS[value] }}
    </AppRadioGroup>

    <AppDropdown.Base
      v-model="country"
      :options="activeCountries"
      :label="$t('Country')"
      :search-placeholder="$t('Search_by_country_name')"
      :error="errors.country"
      required
      searchable
      key-attribute="label"
    />

    <AppDropdown.Base
      v-if="isUSA || isCanada"
      v-model="state"
      :options="activeStates"
      :label="$t('State')"
      :search-placeholder="$t('Search_by_state_name')"
      :error="errors.state"
      required
      searchable
      key-attribute="label"
    />

    <AppTextarea
      v-model="comment"
      :placeholder="$t('please_briefly_describe_request')"
      :error="errors.comment"
      name="comment"
    />

    <Forms.GDPR
      v-model="GDPR"
      :error="errors.GDPR"
      :country-i-s-o="country.isoCode"
    />

    <Buttons.Regular :disabled="!meta.valid" data-id="submit-contactUs">
      {{ btnText }}
      <SvgIcon name="arrow-forward" />
    </Buttons.Regular>
  </form>
</template>

<script setup lang="ts">
// validation
import * as yup from 'yup';
import { toTypedSchema } from '@vee-validate/yup';

// json
import countries from '~/data/countries.json';
import EMEA_ISO_CODES from '~/data/emea-iso-codes.json';

// components
import AppInput from '~/components/redesign/AppInput.vue';
import AppRadioGroup from '~/components/redesign/AppRadioGroup.vue';
import AppTextarea from '~/components/redesign/AppTextarea.vue';
import AppDropdown from '~/components/redesign/AppDropdown';
import Buttons from '~/components/redesign/Buttons';
import Forms from '~/components/redesign/Forms';

// services
import { Cookie } from '~/services';

// stores
import { useCommonStore } from '~/stores/common';

// composables
import { useI18n } from '#i18n';
import { useForm } from 'vee-validate';
import { storeToRefs } from 'pinia';

// utils
import omit from 'lodash.omit';
import pick from 'lodash.pick';
import { filterArray } from '~/utils';

// constants
import { REGEX } from '~/constants';
import { CONNECT_CHANNEL_MANAGER } from '~/constants/api-endpoints';

// types
import type {
  State,
  Countries,
  Country,
  CountryWithStates,
} from '~/types/country';

// exceptions
import { FormValidationError } from '~/exceptions';

const props = defineProps({
  product: {
    type: String,
    required: true,
  },
  page: {
    type: String,
    required: true,
  },
  btnText: {
    type: String,
    default: 'Submit',
  },
  gtmEvent: {
    type: String,
    default: 'partners',
  },
});

const emit = defineEmits<{
  submitted: [];
  loading: [value: boolean];
}>();

const { t } = useI18n();
const { $osano, $gtm, $pp } = useNuxtApp();
const { ipInfo } = storeToRefs(useCommonStore());

const RADIO_OPTIONS = {
  YES: t('yes'),
  NO: t('no'),
} as Record<string, string>;

const RADIO_OPTIONS_KEYS = Object.keys(RADIO_OPTIONS);

const validationSchema = toTypedSchema(
  yup.object({
    fullname: yup
      .string()
      .trim()
      .max(128, ({ max }) => t('Validations.max', { length: max }))
      .required(t('Validations.This_field_is_required'))
      .default(
        filterArray(
          Object.values($pp?.getBulk(['firstname', 'lastname']) || {}),
        ).join(' '),
      ),

    email: yup
      .string()
      .trim()
      .max(128, ({ max }) => t('Validations.max', { length: max }))
      .required(t('Validations.This_field_is_required'))
      .matches(REGEX.EMAIL, t('Validations.Invalid_email_address'))
      .companyEmail()
      .email()
      .default($pp?.get('email') || ''),

    company: yup
      .string()
      .trim()
      .max(128, ({ max }) => t('Validations.max', { length: max }))
      .required(t('Validations.This_field_is_required'))
      .default($pp?.get('company') || ''),

    of_partner: yup
      .string()
      .oneOf(RADIO_OPTIONS_KEYS)
      .required(t('Validations.This_field_is_required')),

    country: yup
      .object()
      .default({ active: true, label: 'United States', isoCode: 'US' })
      .required(t('Validations.This_field_is_required')),

    state: yup
      .object()
      .default({ active: true, label: 'Massachusetts', isoCode: 'MA' })
      .when('country', {
        is: (country?: Country) =>
          ['CA', 'US'].includes(country?.isoCode || ''),
        then: schema =>
          schema.required(t('Validations.This_field_is_required')),
        otherwise: schema => schema.notRequired(),
      }),

    comment: yup
      .string()
      .trim()
      .max(2048, ({ max }) => t('Validations.max', { length: max }))
      .notRequired(),

    GDPR: yup.boolean().when('country', {
      is: (value?: Country) => EMEA_ISO_CODES.includes(value?.isoCode),
      then: schema =>
        schema
          .oneOf([true], t('Validations.This_field_is_required'))
          .required(t('Validations.This_field_is_required')),
      otherwise: schema => schema.notRequired(),
    }),
  }),
);

const {
  errors,
  defineField,
  handleSubmit,
  resetForm,
  meta,
  setFieldValue,
  resetField,
  setErrors,
} = useForm({ validationSchema });

onMounted(() => {
  resetField('of_partner', {
    value: $pp.get('of_partner') || RADIO_OPTIONS_KEYS[0],
  });
});

const [fullname] = defineField('fullname');
const [email] = defineField('email');
const [company] = defineField('company');
const [of_partner] = defineField('of_partner');
const [country] = defineField('country');
const [state] = defineField('state');
const [comment] = defineField('comment');
const [GDPR] = defineField('GDPR');

const activeCountries = computed(() =>
  (countries as Countries).filter(country => country.active),
);

const isUSA = computed(() => country.value?.isoCode === 'US');
const isCanada = computed(() => country.value?.isoCode === 'CA');

const countryHasStates = computed(
  () => country.value && 'states' in country.value,
);
const activeStates = computed(() =>
  countryHasStates.value
    ? (country.value as CountryWithStates).states.filter(state => state.active)
    : [],
);

watch(
  ipInfo,
  info => {
    if (!info) return;

    const foundCountry = (countries as Countries).find(
      country => country.isoCode === info.iso_code,
    );

    if (foundCountry) {
      setFieldValue('country', foundCountry);

      let foundState: State | undefined;

      if ('states' in foundCountry) {
        foundState = foundCountry.states.find(
          item => item.isoCode === info.state,
        );
      }

      setFieldValue('state', foundState || null);
    }
  },
  { immediate: true },
);

watch(country, (newCountry, oldCountry) => {
  if (newCountry.label === oldCountry.label) return;
  resetField('state', { value: null });
});

const formFillingStart = ref<null | number>(null);
const resetFormFillingStart = () => {
  formFillingStart.value = null;
};
const setFormFillingStart = () => {
  if (formFillingStart.value !== null) return;
  formFillingStart.value = Date.now();
};
const getFormFillingSeconds = (): number | undefined => {
  if (formFillingStart.value === null) return;
  return (Date.now() - formFillingStart.value) / 1000;
};

const submitHandler = handleSubmit(async values => {
  const [firstname, lastname] = values.fullname.split(' ');

  const requestBody = {
    ...omit(values, 'GDPR', 'fullname'),

    firstname,
    lastname: lastname || firstname,

    country: values.country.label,
    state: values.state?.label,

    start_time: formFillingStart.value?.toString(),
    of_form_duration: getFormFillingSeconds()?.toString(),

    cookie: Cookie.getCookieArray(),

    page: props.page,
    product: props.product,

    href: window.location.href,

    entry_page: Cookie.get('EntryPage') || '',
    referrer_page: Cookie.get('RefererPage') || '',

    consent: $osano.cm.getConsent(),
  };

  resetFormFillingStart();
  emit('loading', true);

  const {
    public: { api_app: baseURL },
  } = useRuntimeConfig();

  try {
    await $fetch(CONNECT_CHANNEL_MANAGER, {
      method: 'POST',
      baseURL,
      body: requestBody,
      credentials: 'include',
    });

    $pp.updateBulk(
      pick(requestBody, [
        'firstname',
        'lastname',
        'email',
        'company',
        'of_partner',
      ]),
    );

    $gtm.push({ event: props.gtmEvent, email: values.email });
    resetForm();
    Cookie.clearAfterSubmit();
    emit('submitted');
  } catch (error) {
    if (error instanceof FormValidationError) {
      setErrors(error.fields);
    }
  } finally {
    emit('loading', false);
  }
});
</script>

<style scoped lang="scss">
@import '$/functions/token';
@import '$/mixins/typography';
@import '$/mixins/flex';
@import '$/mixins/media';
@import '$/variables/shadows';

[data-component-name='ContactUs'] {
  padding-top: 0.5rem;

  @include flex-start-start;
  flex-direction: column;
  gap: 1.5rem;

  @include tablet {
    gap: 1rem;
  }

  [data-component-name='Buttons.Regular'] {
    align-self: flex-end;

    @include mobile {
      align-self: stretch;
    }
  }
}
</style>
