<template>
  <form
    data-component-name="GetAssetLong"
    autocomplete="off"
    data-id="getAssetLong"
    @input.passive="setFormFillingStart"
    @submit="submitHandler"
  >
    <AppInput
      v-model.trim="fullname"
      :label="$t('Full_Name')"
      :error="errors.fullname"
      required
      type="text"
      name="fullname"
    />

    <AppInput
      v-model="email"
      :label="$t('Corporate_Email')"
      :error="errors.email"
      required
      type="email"
      name="email"
    />

    <AppInput
      v-model="company"
      :label="$t('Company')"
      :error="errors.company"
      required
      type="text"
      name="company"
    />

    <AppDropdown.Base
      v-model="country"
      :options="activeCountries"
      :label="$t('Country')"
      :search-placeholder="$t('Search_by_country_name')"
      :error="errors.country"
      searchable
      required
      key-attribute="label"
    />

    <AppDropdown.Base
      v-if="isUSA || isCanada"
      v-model="state"
      :options="activeStates"
      :label="$t('State')"
      :search-placeholder="$t('Search_by_state_name')"
      :error="errors.state"
      searchable
      required
      key-attribute="label"
    />

    <AppRadioGroup
      v-model="lead_type"
      v-slot="{ value }"
      :options="LEAD_TYPES_KEYS"
      :label="$t('Your_role') + ':'"
      :error="errors.lead_type"
      inline
      required
      name="role"
      id="lead_type"
    >
      {{ LEAD_TYPES[value] }}
    </AppRadioGroup>

    <Forms.GDPR
      v-model="GDPR"
      :error="errors.GDPR"
      :country-i-s-o="country.isoCode"
    />

    <Buttons.Regular
      :disabled="!meta.valid"
      fill="solid"
      accent="purple"
      data-id="submit-getAssetLong"
    >
      {{ $t('Submit') }}
      <SvgIcon name="arrow-forward" />
    </Buttons.Regular>
  </form>
</template>

<script setup lang="ts">
// validation
import * as yup from 'yup';
import { toTypedSchema } from '@vee-validate/yup';

// components
import AppInput from '~/components/redesign/AppInput.vue';
import AppRadioGroup from '~/components/redesign/AppRadioGroup.vue';
import AppDropdown from '~/components/redesign/AppDropdown';
import Buttons from '~/components/redesign/Buttons';
import Forms from '~/components/redesign/Forms';

// services
import { Cookie } from '~/services';

// stores
import { useCommonStore } from '~/stores/common';

// composables
import { useI18n } from '#i18n';
import { useForm } from 'vee-validate';
import { storeToRefs } from 'pinia';

// utils
import omit from 'lodash.omit';
import pick from 'lodash.pick';
import { filterArray } from '~/utils';

// constants
import { REGEX } from '~/constants';
import { WHITE_PAPER } from '~/constants/api-endpoints';

// JSON
import countries from '~/data/countries.json';
import EMEA_ISO_CODES from '~/data/emea-iso-codes.json';

import type {
  State,
  Countries,
  Country,
  CountryWithStates,
} from '~/types/country';

// exceptions
import { FormValidationError } from '~/exceptions';

type Props = {
  page?: 'white_paper' | 'zero-trust-data-resilience';
  customCampaign?: string;
};

const props = withDefaults(defineProps<Props>(), {
  page: 'white_paper',
});
const emit = defineEmits<{ loading: [value: boolean]; submitted: [] }>();

const { t } = useI18n();
const { $osano, $gtm, $pp } = useNuxtApp();
const { ipInfo } = storeToRefs(useCommonStore());

const LEAD_TYPES = {
  CUSTOMER: t('Customer'),
  PARTNER: t('Partner'),
} as Record<string, string>;

const LEAD_TYPES_KEYS = Object.keys(LEAD_TYPES);

const validationSchema = toTypedSchema(
  yup.object({
    fullname: yup
      .string()
      .trim()
      .max(128, ({ max }) => t('Validations.max', { length: max }))
      .required(t('Validations.This_field_is_required'))
      .default(
        filterArray(
          Object.values($pp?.getBulk(['firstname', 'lastname']) || {}),
        ).join(' '),
      ),

    email: yup
      .string()
      .trim()
      .max(128, ({ max }) => t('Validations.max', { length: max }))
      .required(t('Validations.This_field_is_required'))
      .matches(REGEX.EMAIL, t('Validations.Invalid_email_address'))
      .companyEmail()
      .email()
      .default($pp?.get('email')),

    company: yup
      .string()
      .trim()
      .max(128, ({ max }) => t('Validations.max', { length: max }))
      .required(t('Validations.This_field_is_required'))
      .default($pp?.get('company')),

    country: yup
      .object()
      .default({ active: true, label: 'United States', isoCode: 'US' })
      .required(t('Validations.This_field_is_required')),

    state: yup
      .object()
      .default({ active: true, label: 'Massachusetts', isoCode: 'MA' })
      .when('country', {
        is: (country?: Country) =>
          ['CA', 'US'].includes(country?.isoCode || ''),
        then: schema =>
          schema.required(t('Validations.This_field_is_required')),
        otherwise: schema => schema.notRequired(),
      }),

    lead_type: yup
      .string()
      .oneOf(LEAD_TYPES_KEYS)
      .required(t('Validations.This_field_is_required')),

    GDPR: yup.boolean().when('country', {
      is: (value?: Country) => EMEA_ISO_CODES.includes(value?.isoCode),
      then: schema =>
        schema
          .oneOf([true], t('Validations.This_field_is_required'))
          .required(t('Validations.This_field_is_required')),
      otherwise: schema => schema.notRequired(),
    }),
  }),
);

const {
  errors,
  defineField,
  handleSubmit,
  resetForm,
  meta,
  setFieldValue,
  resetField,
  setErrors,
} = useForm({ validationSchema });

onMounted(() => {
  resetField('lead_type', {
    value: $pp.get('lead_type') || LEAD_TYPES_KEYS[0],
  });
});

const [fullname] = defineField('fullname');
const [email] = defineField('email');
const [company] = defineField('company');
const [lead_type] = defineField('lead_type');
const [country] = defineField('country');
const [state] = defineField('state');
const [GDPR] = defineField('GDPR');

const activeCountries = computed(() =>
  (countries as Countries).filter(country => country.active),
);

const isUSA = computed(() => country.value?.isoCode === 'US');
const isCanada = computed(() => country.value?.isoCode === 'CA');

const countryHasStates = computed(
  () => country.value && 'states' in country.value,
);
const activeStates = computed(() =>
  countryHasStates.value
    ? (country.value as CountryWithStates).states.filter(state => state.active)
    : [],
);

watch(
  ipInfo,
  info => {
    if (!info) return;

    const foundCountry = (countries as Countries).find(
      country => country.isoCode === info.iso_code,
    );

    if (foundCountry) {
      setFieldValue('country', foundCountry);

      let foundState: State | undefined;

      if ('states' in foundCountry) {
        foundState = foundCountry.states.find(
          item => item.isoCode === info.state,
        );
      }

      setFieldValue('state', foundState || null);
    }
  },
  { immediate: true },
);

watch(country, (newCountry, oldCountry) => {
  if (newCountry.label === oldCountry.label) return;
  resetField('state', { value: null });
});

const formFillingStart = ref<null | number>(null);
const resetFormFillingStart = () => {
  formFillingStart.value = null;
};
const setFormFillingStart = () => {
  if (formFillingStart.value !== null) return;
  formFillingStart.value = Date.now();
};
const getFormFillingSeconds = (): number | undefined => {
  if (formFillingStart.value === null) return;
  return (Date.now() - formFillingStart.value) / 1000;
};

const submitHandler = handleSubmit(async values => {
  const [firstname, lastname] = values.fullname.split(' ');

  const requestBody = {
    ...omit(values, 'GDPR', 'fullname'),

    firstname,
    lastname: lastname || firstname,

    country: values.country.label,
    state: values.state?.label,

    start_time: formFillingStart.value?.toString(),
    of_form_duration: getFormFillingSeconds()?.toString(),

    cookie: Cookie.getCookieArray(),

    href: window.location.href,
    page: props.page,

    entry_page: Cookie.get('EntryPage') || '',
    referrer_page: Cookie.get('RefererPage') || '',
    custom_campaign: props.customCampaign,

    consent: $osano.cm.getConsent(),
  };

  resetFormFillingStart();
  emit('loading', true);

  const {
    public: { api_app: baseURL },
  } = useRuntimeConfig();

  try {
    await $fetch(WHITE_PAPER, {
      method: 'POST',
      baseURL,
      body: requestBody,
      credentials: 'include',
    });

    $pp.updateBulk(
      pick(requestBody, [
        'firstname',
        'lastname',
        'email',
        'company',
        'lead_type',
      ]),
    );

    $gtm.push({ event: 'whitepaper', email: values.email });
    resetForm();
    Cookie.clearAfterSubmit();
    emit('submitted');
  } catch (error) {
    if (error instanceof FormValidationError) {
      setErrors(error.fields);
    }
  } finally {
    emit('loading', false);
  }
});
</script>

<style scoped lang="scss">
@import '$/functions/token';
@import '$/mixins/typography';
@import '$/mixins/flex';
@import '$/mixins/media';
@import '$/variables/shadows';

[data-component-name='GetAssetLong'] {
  padding-top: 0.5rem;

  @include flex-start-start;
  flex-direction: column;
  gap: 1.5rem;

  .terms {
    @include flex-start-start;
    gap: 0.25rem;

    p {
      @include caption;

      label {
        display: block;
        margin-bottom: 0.5rem;
        cursor: pointer;

        &.error {
          @include caption;
          color: token('error');
        }
      }

      a {
        color: token('link');
        text-decoration: underline;
      }
    }
  }

  [data-component-name='Buttons.Regular'] {
    align-self: flex-end;

    @include mobile {
      align-self: stretch;
    }
  }
}
</style>
