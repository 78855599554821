<script lang="ts" setup>
import SvgIcon from "~/components/SvgIcon.vue";

interface Props {
  maxStars?: number;
}

withDefaults(defineProps<Props>(), {
  maxStars: 10,
});

const modelValue = defineModel<number>({
  required: false,
  default: 0,
});

const hoveredStarCount = ref<number>(0);

const onSetRating = (value: number): void => {
  modelValue.value = modelValue.value === value ? 0 : value;
};
</script>

<template>
  <div data-component-name="StarRating" @mouseleave="hoveredStarCount = 0">
    <button
      v-for="(starCount, index ) in maxStars"
      :key="`rating-${index}`"
      type="button"
      @click="onSetRating(starCount)"
      @mouseover="hoveredStarCount = starCount"
    >
      <SvgIcon
        :class="{
          filled: (modelValue >= starCount && !hoveredStarCount) || hoveredStarCount >= starCount,
        }"
        name="star"
      />
    </button>
  </div>
</template>

<style lang="scss" scoped>
@use "_/mixins/flex";
@use "_/mixins/size";
@use "_/fn";

[data-component-name="StarRating"] {
  @include flex.center-center;
  margin: 0 auto;

  button {
    svg {
      @include size.fixed(2.5rem);
      margin: 0 -0.25rem;

      color: fn.token('icon-tertiary');

      transition: color 0.075s ease-in-out;
    }

    .filled {
      fill: #facc15;
    }
  }
}
</style>
