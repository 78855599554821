<template>
  <div data-component-name="Forms.GDPR">
    <GDPRForEMEA
      v-if="EMEA_ISO_CODES.includes(countryISO || '')"
      v-model="modelValue"
      :accent
      :disabled
      :error
    />

    <GDPRForUSA v-else-if="countryISO === 'US'" :accent />

    <GDPRForGlobal v-else :accent />
  </div>
</template>

<script setup lang="ts">
import GDPRForEMEA from './EMEA.vue';
import GDPRForGlobal from './Global.vue';
import GDPRForUSA from './USA.vue';

// data
import EMEA_ISO_CODES from '~/data/emea-iso-codes.json';

const modelValue = defineModel<boolean>('modelValue', {
  default: false,
});

withDefaults(
  defineProps<{
    countryISO?: string;
    error?: string;
    disabled?: boolean;
    accent?: 'light' | 'dark';
  }>(),
  {
    disabled: false,
    accent: 'dark',
  },
);
</script>
