<template>
  <form
    data-component-name="Forms.HelpFeedback"
    autocomplete="off"
    data-id="HelpFeedback"
    @input.passive="setFormFillingStart"
    @submit="submitForm"
  >
    <StarRating v-model="feedback_score" />

    <AppTextarea
      v-model="comment"
      :placeholder="$t('Type_here')"
      :error="errors.comment"
      name="comment"
    />

    <Buttons.Regular
      :disabled="!meta.valid || isSubmitting"
      data-id="submit-help-feedback"
      fill="solid"
      accent="purple"
    >
      {{ $t("Submit") }}

      <SvgIcon name="arrow-forward" />
    </Buttons.Regular>
  </form>
</template>

<script setup lang="ts">
// validation
import * as yup from 'yup';
import { toTypedSchema } from '@vee-validate/yup';

// components
import AppTextarea from '~/components/redesign/AppTextarea.vue';
import Buttons from '~/components/redesign/Buttons';
import StarRating from '~/components/redesign/StarRating.vue';

// services
import Cookie from '~/services/Cookie';

// stores
import { useHelpPageStore } from '~/stores/help';

// composables
import { useI18n } from '#i18n';
import { useForm } from 'vee-validate';
import { storeToRefs } from 'pinia';

// constants
import { API_ENDPOINTS, COOKIE_KEYS } from '~/constants';

// exceptions
import { FormValidationError } from '~/exceptions';

const emit = defineEmits<{ loading: [value: boolean], submitted: [] }>();

const { t } = useI18n();
const { $osano } = useNuxtApp();

const validationSchema = toTypedSchema(yup.object({
  feedback_score: yup
    .number()
    .required(t('Validations.This_field_is_required')),

  comment: yup
    .string()
    .max(2056, ({ max }) => t('Validations.max', { length: max })),
}));

const {
  errors,
  defineField,
  handleSubmit,
  resetForm,
  meta,
  isSubmitting,
  setErrors,
} = useForm({ validationSchema });

const [feedback_score] = defineField('feedback_score');
const [comment] = defineField('comment');
const { currentPageId } = storeToRefs(useHelpPageStore());

const formFillingStart = ref<null | number>(null);
const resetFormFillingStart = () => {
  formFillingStart.value = null;
};
const setFormFillingStart = () => {
  if (formFillingStart.value !== null) return;
  formFillingStart.value = Date.now();
};
const getFormFillingSeconds = (): number | undefined => {
  if (formFillingStart.value === null) return;
  return (Date.now() - formFillingStart.value) / 1000;
};

const submitForm = handleSubmit(async ({ feedback_score, comment }) => {
  const requestBody = {
    feedback_score,
    comment,

    help_page_id: currentPageId.value,

    start_time: formFillingStart.value?.toString(),
    of_form_duration: getFormFillingSeconds()?.toString(),

    cookie: Cookie.getCookieArray(),

    href: window.location.href,

    consent: $osano.cm.getConsent(),
  };

  resetFormFillingStart();

  emit('loading', true);

  const { public: { api_v2: baseURL } } = useRuntimeConfig();

  try {
    await $fetch(API_ENDPOINTS.HELP_PAGES_FEEDBACK, {
      method: 'POST',
      baseURL,
      body: requestBody,
      credentials: 'include',
    });

    Cookie.set(COOKIE_KEYS.HELP_FEEDBACK_SUBMITTED, true);

    resetForm();
    emit('submitted');
  } catch (error) {
    if (error instanceof FormValidationError) {
      setErrors(error.fields);
    }
  } finally {
    emit('loading', false);
  }
});
</script>

<style scoped lang="scss">
@use "_/mixins/flex";

[data-component-name="Forms.HelpFeedback"] {
  @include flex.center-center;
  flex-direction: column;
  gap: 1rem;

  [data-component-name="Buttons.Regular"] {
    width: 100%;
  }
}
</style>
