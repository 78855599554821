<template>
  <div
    :class="{ disabled, inline }"
    data-component-name="AppRadioGroup"
  >
    <strong>
      {{ label + (required ? '*' : '') }}
    </strong>

    <ul>
      <li v-for="option of options" :key="option">
        <AppRadio
          v-model="modelValue"
          :value="option"
          name="role"
          :id="id + '_' + option"
        />

        <label :for="id + '_' + option">
          <slot :value="option">
            {{ option }}
          </slot>
        </label>
      </li>
    </ul>

    <span
      v-if="error"
      v-html="error"
      class="error"
    />
  </div>
</template>

<script lang="ts" setup>
import AppRadio from '~/components/redesign/AppRadio.vue';

withDefaults(
  defineProps<{
    label: string
    options: readonly string[]
    id: string
    name: string
    error?: string
    disabled?: boolean
    inline?: boolean
    required: boolean
  }>(),
  {
    disabled: false,
    inline: false,
    required: false
  },
);

const modelValue = defineModel<string>()
</script>

<style lang="scss" scoped>
@import "$/functions/token";
@import "$/mixins/typography";
@import "$/mixins/flex";
@import "$/mixins/media";

[data-component-name="AppRadioGroup"] {
  padding: 0 1rem;

  strong {
    @include subtitle-3;

    display: block;
    margin-bottom: 0.5rem;
  }

  ul {
    @include flex-start-start;
    gap: 0.94rem;

    li {
      @include flex-center-start;
      gap: 0.13rem;

      label {
        cursor: pointer;
        @include body-3;
        text-transform: capitalize;
      }
    }
  }

  span.error {
    @include caption;
    color: token('error');
    margin-top: 0.25rem;
    display: block;
  }

  &.inline {
    @include flex-center-center;
    column-gap: 2rem;

    strong {
      margin-bottom: 0;
    }

    @include mobile {
      padding: 0 0.5rem;
      column-gap: 1rem;

      ul {
        gap: 0.5rem;
      }
    }
  }

  &.disabled {
    strong {
      color: token('text-tertiary');
    }

    ul {
      cursor: not-allowed;

      li {
        pointer-events: none;

        [data-component-name="AppRadio"] {
          opacity: 0.15;
          filter: brightness(0);
        }

        label {
          color: token('text-tertiary');
          cursor: not-allowed;
        }
      }
    }
  }
}
</style>
