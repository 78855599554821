<script lang="ts" setup>
// validation
import * as yup from 'yup';
import { toTypedSchema } from '@vee-validate/yup';

// components
import AppInput from '~/components/redesign/AppInput.vue';
import AppRadioGroup from '~/components/redesign/AppRadioGroup.vue';
import AppDropdown from '~/components/redesign/AppDropdown';
import Buttons from '~/components/redesign/Buttons';
import Forms from '~/components/redesign/Forms';

// stores
import { useCommonStore } from '~/stores/common';

// composables
import { useI18n } from '#i18n';
import { useForm } from 'vee-validate';
import { storeToRefs } from 'pinia';

// constants
import { REGEX } from '~/constants';

// JSON
import countries from '~/data/countries.json';
import EMEA_ISO_CODES from '~/data/emea-iso-codes.json';

// types
import type { BaseButtonProps } from '~/components/redesign/Buttons/Base.types';
import type {
  State,
  Countries,
  Country,
  CountryWithStates,
} from '~/types/country';

// utils
import pick from 'lodash.pick';
import { sleep, isString } from '~/utils';

// services
import Cookie from '~/services/Cookie';

// exceptions
import { FormValidationError } from '~/exceptions';

const props = withDefaults(
  defineProps<{
    btnText?: string;
    gtmEvent?: string;
    accent?: BaseButtonProps['accent'];
    size?: 'small' | 'medium' | 'large';
  }>(),
  {
    btnText: 'Submit',
    gtmEvent: 'demo',
    accent: 'purple',
    size: 'medium',
  },
);

const emit = defineEmits<{
  submitted: [];
  loading: [value: boolean];
  emailSubmitted: [value: boolean];
  emailVerified: [value: boolean];
}>();

const { t } = useI18n();
const { ipInfo } = storeToRefs(useCommonStore());
const { $gtm, $pp } = useNuxtApp();

const LEAD_TYPES = {
  CUSTOMER: t('Customer'),
  PARTNER: t('Partner'),
} as Record<string, string>;

const LEAD_TYPES_KEYS = Object.keys(LEAD_TYPES);

const isMatchingEmail = (value = ''): boolean => {
  const storedEmail = $pp.get('email');
  return (
    isString(storedEmail) && storedEmail.toLowerCase() === value.toLowerCase()
  );
};

const validationSchema = toTypedSchema(
  yup.object({
    fullname: yup
      .string()
      .trim()
      .max(128, ({ max }) => t('Validations.max', { length: max }))
      .required(t('Validations.This_field_is_required')),

    email: yup
      .string()
      .trim()
      .max(128, ({ max }) => t('Validations.max', { length: max }))
      .matches(REGEX.EMAIL, t('Validations.Invalid_email_address'))
      .companyEmail()
      .email()
      .required(t('Validations.This_field_is_required')),

    company: yup
      .string()
      .trim()
      .max(128, ({ max }) => t('Validations.max', { length: max }))
      .required(t('Validations.This_field_is_required')),

    lead_type: yup
      .string()
      .oneOf(LEAD_TYPES_KEYS)
      .required(t('Validations.This_field_is_required'))
      .default(LEAD_TYPES_KEYS[0]),

    country: yup
      .object()
      .default({ active: true, label: 'United States', isoCode: 'US' })
      .required(t('Validations.This_field_is_required')),

    state: yup
      .object()
      .default({ active: true, label: 'Massachusetts', isoCode: 'MA' })
      .when('country', {
        is: (country?: Country) =>
          ['CA', 'US'].includes(country?.isoCode || ''),
        then: schema =>
          schema.required(t('Validations.This_field_is_required')),
        otherwise: schema => schema.notRequired(),
      }),

    GDPR: yup.boolean().when('country', {
      is: (value?: Country) => EMEA_ISO_CODES.includes(value?.isoCode),
      then: schema =>
        schema
          .oneOf([true], t('Validations.This_field_is_required'))
          .required(t('Validations.This_field_is_required')),
      otherwise: schema => schema.notRequired(),
    }),
  }),
);

const {
  errors,
  defineField,
  handleSubmit,
  resetForm,
  setFieldValue,
  resetField,
  setErrors,
  setValues,
  meta,
} = useForm({ validationSchema });

const [fullname] = defineField('fullname');
const [email] = defineField('email');
const [company] = defineField('company');
const [lead_type] = defineField('lead_type');
const [country] = defineField('country');
const [state] = defineField('state');
const [GDPR] = defineField('GDPR');

const activeCountries = computed(() =>
  (countries as Countries).filter(country => country.active),
);

const isUSA = computed(() => country.value?.isoCode === 'US');
const isCanada = computed(() => country.value?.isoCode === 'CA');

const countryHasStates = computed(
  () => country.value && 'states' in country.value,
);
const activeStates = computed(() =>
  countryHasStates.value
    ? (country.value as CountryWithStates).states.filter(state => state.active)
    : [],
);

const emailVerificationSuccess = ref(false);
const emailVerificationMessage = ref();

watch(
  ipInfo,
  info => {
    if (!info) return;

    const foundCountry = (countries as Countries).find(
      country => country.isoCode === info.iso_code,
    );

    if (foundCountry) {
      setFieldValue('country', foundCountry);

      let foundState: State | undefined;

      if ('states' in foundCountry) {
        foundState = foundCountry.states.find(
          item => item.isoCode === info.state,
        );
      }

      setFieldValue('state', foundState || null);
    }
  },
  { immediate: true },
);

watch(country, (newCountry, oldCountry) => {
  if (newCountry.label === oldCountry.label) return;
  resetField('state', { value: null });
});

const isEmailValid = computed(
  () => isString(email.value) && !isString(errors.value?.email),
);

const isEmailVerified = computed(
  () => isString(email.value) && isMatchingEmail(email.value),
);

const canFillForm = computed(
  () =>
    isEmailValid.value ||
    errors.value?.email === t('Validations.email.not-found'),
);

watch(canFillForm, value => emit('emailSubmitted', value));

watch(errors, async errorsValue => {
  if (
    isString(email.value) &&
    !isString(errorsValue.email) &&
    !isMatchingEmail(email.value)
  ) {
    emailVerificationMessage.value = t('Validations.email.not-found');
    emailVerificationSuccess.value = false;
  }
});

watch(isEmailVerified, value => {
  if (value) {
    emailVerificationMessage.value = t('Validations.email.verified');
    emailVerificationSuccess.value = true;

    setValues(
      {
        fullname: filterArray(
          Object.values($pp.getBulk(['firstname', 'lastname'])),
        ).join(' '),
        ...$pp.getBulk(['company', 'lead_type']),
      },
      false,
    );

    emit('emailVerified', value);
  }
});

const submitHandler = handleSubmit(async values => {
  const [firstname, lastname] = values.fullname.split(' ');

  emit('loading', true);

  try {
    await sleep(500);
    // TODO: credentials: 'include'

    $pp.updateBulk({
      ...pick(values, ['email', 'company', 'lead_type']),
      firstname,
      lastname,
    });

    $gtm.push({ event: props.gtmEvent, email: values.email });
    resetForm({ values: pick(values, ['country', 'state']) });
    Cookie.clearAfterSubmit();

    emit('submitted');
  } catch (error) {
    if (error instanceof FormValidationError) {
      setErrors(error.fields);
    }
  } finally {
    emit('loading', false);
  }
});
</script>

<template>
  <form
    data-component-name="Forms.VerifyEmail"
    autocomplete="off"
    data-id="VerifyEmail"
    @submit="submitHandler"
  >
    <AppInput
      v-model.trim="fullname"
      :label="$t('Full_Name')"
      :error="errors.fullname"
      :disabled="!canFillForm"
      required
      type="text"
      name="fullname"
    />

    <AppInput
      v-model="email"
      :label="$t('Corporate_Email')"
      :error="errors.email"
      :message="emailVerificationMessage"
      :success="emailVerificationSuccess"
      required
      type="email"
      name="email"
    />

    <AppInput
      v-model="company"
      :label="$t('Company')"
      :error="errors.company"
      :disabled="!canFillForm"
      required
      type="text"
      name="company"
    />

    <AppDropdown.Base
      v-model="country"
      :options="activeCountries"
      :label="$t('Country')"
      :search-placeholder="$t('Search_by_country_name')"
      :error="errors.country"
      :disabled="!canFillForm"
      searchable
      required
      key-attribute="label"
    />

    <AppDropdown.Base
      v-if="isUSA || isCanada"
      v-model="state"
      :options="activeStates"
      :label="$t('State')"
      :search-placeholder="$t('Search_by_state_name')"
      :error="errors.state"
      :disabled="!canFillForm"
      searchable
      required
      key-attribute="label"
    />

    <AppRadioGroup
      v-model="lead_type"
      v-slot="{ value }"
      :options="LEAD_TYPES_KEYS"
      :label="$t('Your_role') + ':'"
      :error="errors.lead_type"
      :disabled="!canFillForm"
      inline
      required
      name="role"
      id="lead_type"
    >
      {{ LEAD_TYPES[value] }}
    </AppRadioGroup>

    <Forms.GDPR
      v-model="GDPR"
      :error="errors.GDPR"
      :country-i-s-o="country.isoCode"
      :disabled="!canFillForm"
    />

    <Buttons.Regular
      :disabled="!meta.valid"
      fill="solid"
      accent="purple"
      data-id="submit-getAssetLong"
    >
      {{ $t('Submit') }}
      <SvgIcon name="arrow-forward" />
    </Buttons.Regular>
  </form>
</template>

<style lang="scss" scoped>
@use '_/mixins/flex';
@use '_/mixins/typo';

[data-component-name='Forms.VerifyEmail'] {
  @include flex.start-start;
  flex-direction: column;
  gap: 1.5rem;

  :deep([data-component-name='AppRadioGroup']) {
    strong {
      @include typo.body-3;
    }
  }

  [data-component-name='Buttons.Regular'] {
    align-self: flex-end;
  }
}
</style>
