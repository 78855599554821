<script setup lang="ts">
// components
import SvgIcon from '~/components/SvgIcon.vue';

withDefaults(
  defineProps<{
    label?: string;
    placeholder: string;
    accept: string;

    required?: boolean;
    disabled?: boolean;

    error?: string;

    id?: string;
    name?: string;
  }>(),
  {
    required: false,
    disabled: false,
    id: () => useId(),
  },
);

const emit = defineEmits<{
  change: any;
}>();

const key = ref(0);
const file = ref(null);

const interval = ref(null);
const progress = ref(0);
const isLoading = ref(false);
const isComplete = ref(false);

const startProgress = () => {
  isLoading.value = true;

  let incrementStep = 10;

  interval.value = setInterval(() => {
    if (progress.value >= 100) {
      finishFileLoad();

      isComplete.value = true;
    } else {
      progress.value += incrementStep;

      if (progress.value > 70) {
        incrementStep = 5;
      }
    }
  }, 100);
};

const stopProgress = () => {
  if (interval.value) {
    clearInterval(interval.value);
    interval.value = null;
  }
};

const finishFileLoad = () => {
  stopProgress();

  emit('change', file.value);
}

const clearProgress = () => {
  progress.value = 0;
};

const clear = () => {
  stopProgress();

  clearProgress();

  isLoading.value = false;
  isComplete.value = false;

  // re-render input
  key.value += 1;

  file.value = null;
  emit('change', file.value);
};

const onChange = (event: Event) => {
  if (
    (event.target as HTMLInputElement).files &&
    (event.target as HTMLInputElement).files.length
  ) {
    file.value = event.target.files[0];

    startProgress();
  }
}
</script>

<template>
  <div data-component-name="AppInputFile">
    <div class="inner">
      <strong>
        {{ label }}
        <span v-if="required">*</span>
      </strong>

      <input
        :key

        :accept
        :disabled
        :name

        type="file"

        :id
        @change="onChange($event)"
      >

      <div class="right">
        <span v-if="isLoading">
          <progress v-if="!isComplete" :value="progress" max="100" />

          <p v-if="isComplete">{{ file.name }}</p>

          <button type="button" aria-label="clear" @click="clear">
            <SvgIcon name="close" />
          </button>
        </span>

        <label v-else :for="id">
          {{ $t('Attach') }}
        </label>

        <p>{{ placeholder }}</p>
      </div>
    </div>

    <span v-if="error" v-html="error" class="error" />
  </div>
</template>

<style lang="scss" scoped>
@use "_/fn";
@use "_/mixins/position";
@use "_/mixins/size";
@use "_/mixins/media";
@use "_/mixins/typo";
@use "_/mixins/flex";
@use "_/mixins/component";
@use "_/mixins/text-transform";

[data-component-name="AppInputFile"] {
  width: 100%;
  padding: 0 1rem;

  input {
    display: none;
  }

  .inner {
    @include flex.start-start;
    gap: 1rem;

    @include media.mobile {
      @include flex.start-start;
      flex-direction: column;
    }

    strong {
      @include typo.subtitle-3;

      text-wrap: nowrap;
    }

    .right {
      span {
        @include flex.center-start;
        flex-direction: row;

        progress {
          -webkit-appearance: none;
          appearance: none;

          width: 100%;
          height: 4px;
          border: none;

          background-color: fn.token('surf-cont-secondary');

          overflow: hidden;
          position: relative;

          transition: width 0.15s ease;

          &::-webkit-progress-bar {
            background-color: fn.token('surf-cont-secondary');
          }

          &::-webkit-progress-value {
            background-color: fn.token("outline-action-secondary");
          }

          &::-moz-progress-bar {
            background-color: fn.token("outline-action-secondary");
          }

          &::-ms-fill {
            background-color: fn.token("outline-action-secondary");
          }

          &::after {
            content: "";
            @include position.absolute-full;
            border: 1px solid fn.token("outline-action-secondary");
            pointer-events: none;
          }
        }

        button {
          margin-left: 0.5rem;
          @include flex.center-center;

          svg {
            @include size.fixed(1.5rem);
          }
        }
      }

      label {
        @include component.app-link;
        cursor: pointer;
      }

      p {
        @include typo.body-4;
        color: fn.token("text-secondary");

        overflow: hidden;
        text-overflow: ellipsis;

        max-width: 22rem;

        @include media.mobile {
          max-width: 15rem;
        }
      }
    }
  }

  span.error {
    @include typo.caption;
    @include text-transform.capitalize-first;
    color: fn.token("error");
    margin: 0.25rem 0 0;
    display: block;
  }
}
</style>
