<template>
  <div 
    :class="[{ disabled }, accent]"
    data-component-name="Forms.GDPR.EMEA"
  >
    <AppCheckbox
      :model-value="modelValue"
      :accent
      name="terms"
      :id
      @update:model-value="checkboxClickHandler"
    />

    <div class="right">
      <label :for="id" :class="{ error }" @click="labelClickHandler">
        {{ $t("Forms.GDPR.EMEA.label") }}
      </label>

      <i18n-t keypath="Forms.GDPR.EMEA.disclaimer" tag="p">
        <LinkPrivacyNotice :accent />
      </i18n-t>

      <p>{{ $t("Forms.GDPR.unsubscribe") }}</p>
    </div>
  </div>
</template>

<script setup lang="ts">
// components
import AppCheckbox from '~/components/redesign/AppCheckbox.vue';
import LinkPrivacyNotice from '~/components/redesign/LinkPrivacyNotice.vue';

const props = withDefaults(
  defineProps<{
    error?: string;
    disabled?: boolean;
    accent?: 'light' | 'dark';
  }>(),
  {
    disabled: false,
    accent: 'dark',
  },
);

const modelValue = defineModel('modelValue', { default: false });

const id = useId();

const checkboxClickHandler = (value: boolean) => {
  if (!props.disabled) {
    modelValue.value = value;
  }
};

const labelClickHandler = (e: Event) => {
  if (props.disabled) {
    e.preventDefault();
  }
};
</script>

<style scoped lang="scss">
@use "_/mixins/flex";
@use "_/mixins/typo";
@use "_/fn";

[data-component-name="Forms.GDPR.EMEA"] {
  @include flex.start-start;
  gap: 0.25rem;

  transition: opacity 0.15s ease;

  .right {
    flex-direction: column;
    @include flex.start-start;
    gap: 0.5rem;

    @include typo.caption;

    label,
    p {
      display: block;
      margin-bottom: 0.5rem;
      cursor: pointer;

      &.error {
        @include typo.caption;
        color: fn.token("error");
      }
    }

    a {
      color: fn.token("link");
      text-decoration: underline;
    }
  }

  &.disabled {
    opacity: 0.25;
    user-select: none;

    *:not(a) {
      cursor: not-allowed;
    }
  }

  &.dark {
    p {
      color: fn.token("text-primary");
    }
  }

  &.light {
    p {
      color: fn.token("text-reversed");
    }
  }
}
</style>
