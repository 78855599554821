<script setup lang="ts">
// validation
import * as yup from 'yup';
import { useForm } from 'vee-validate';
import { toTypedSchema } from '@vee-validate/yup';

// stores
import useUIStore from '~/stores/ui';
import { useCommonStore } from '~/stores/common';

// components
import SvgIcon from '~/components/SvgIcon.vue';
import Forms from '~/components/redesign/Forms';
import Buttons from '~/components/redesign/Buttons';
import AppInput from '~/components/redesign/AppInput.vue';

// composables
import { useI18n } from '#i18n';
import { storeToRefs } from 'pinia';

// utils
import get from 'lodash.get';
import omit from 'lodash.omit';

// constants
import { REGEX, API_ENDPOINTS, STATUS_CODES } from '~/constants';

// data
import EMEA_ISO_CODES from '~/data/emea-iso-codes.json';

// exceptions
import { FormValidationError } from '~/exceptions';

const emit = defineEmits<{ change: [message: string] }>();

const { t } = useI18n();
const {
  public: { api_app: baseURL },
} = useRuntimeConfig();

const uiStore = useUIStore();
const commonStore = useCommonStore();
const { $osano, $pp } = useNuxtApp();

const { setLoading } = uiStore;
const { ipInfo } = storeToRefs(commonStore);

const country = computed(() => get(ipInfo.value, 'country'));
const countryISO = computed(() => get(ipInfo.value, 'iso_code'));

const validationSchema = toTypedSchema(
  yup.object({
    email: yup
      .string()
      .matches(REGEX.EMAIL, t('Validations.Invalid_email_address'))
      .companyEmail()
      .email()
      .required(t('Validations.This_field_is_required'))
      .default($pp?.get('email')),

    gdpr: yup.boolean().when({
      is: () => EMEA_ISO_CODES.includes(countryISO.value),
      then: schema =>
        schema
          .oneOf([true], t('Validations.This_field_is_required'))
          .required(t('Validations.This_field_is_required')),
      otherwise: schema => schema.notRequired(),
    }),
  }),
);

const {
  meta,
  errors,
  defineField,
  handleSubmit,
  setErrors,
  resetForm,
} = useForm({ validationSchema });

const [email] = defineField('email');
const [gdpr] = defineField('gdpr');

const submitHandler = handleSubmit(async values => {
  try {
    setLoading(true);

    await $fetch(API_ENDPOINTS.SUBSCRIBE, {
      baseURL,
      method: 'POST',
      body: {
        ...omit(values, 'gdpr'),
        country: country.value,
        consent: $osano.cm.getConsent(),
      },
      credentials: 'include',
    });

    $pp.update('email', values.email);
    resetForm();
    emit('change', t('AppSubscribe.subscribed'));
  } catch (error) {
    if (error instanceof FormValidationError) {
      if (error.code === STATUS_CODES.CONFLICT) {
        emit('change', t('AppSubscribe.already-subscribed'));
      } else {
        setErrors({ email: t('Validations.real_email') });
      }
    }
  } finally {
    setLoading(false);
  }
});
</script>

<template>
  <form
    data-component-name="Forms.Subscribe"
    autocomplete="off"
    @submit="submitHandler"
  >
    <div class="input-wrapper">
      <AppInput
        v-model="email"
        :label="$t('Corporate_Email')"
        :error="errors.email"
        required
        type="email"
        fill="outline"
      />

      <Buttons.Regular :disabled="!meta.valid" accent="purple" class="nowrap">
        {{ $t('Subscribe') }}
        <SvgIcon name="arrow-forward" />
      </Buttons.Regular>
    </div>

    <Forms.GDPR v-model="gdpr" :error="errors.gdpr" :country-i-s-o />
  </form>
</template>

<style scoped lang="scss">
@use '_/mixins/flex';
@use '_/mixins/media';

[data-component-name='Forms.Subscribe'] {
  .input-wrapper {
    @include flex.start-start;
    margin-bottom: 0.5rem;

    [data-component-name='Buttons.Regular'] {
      height: 3.625rem;
    }

    @include media.mobile {
      gap: 0.5rem 0;
      flex-direction: column;

      [data-component-name='Buttons.Regular'] {
        height: auto;
        align-self: stretch;
      }
    }
  }
}
</style>
